import React from 'react';
import ReactDOM from 'react-dom/client';
import './polyfills.js';

import { ErrorBoundary } from 'react-error-boundary';
import App from './Root.jsx';
import reportWebVitals from './reportWebVitals.js';
import ErrorFallback from './utils/ErrorFallback.jsx';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={ErrorFallback} onReset={() => window.location.replace('/#/')}>
        <App />
    </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
