import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext({});
const useNavigation = () => useContext(NavigationContext);

function NavigationProvider(props) {
    const [navigationData, setNavigationData] = useState({ currentPath: '' });

    return <NavigationContext.Provider value={{ navigationData, setNavigationData }} {...props} />;
}

export { NavigationProvider, useNavigation };
