import { fetchWrapper } from '../../utils/fetch-wrapper';

export async function get() {
    let response = await fetchWrapper('/api/notifications', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.status === 204) {
        return [];
    }
    return response.json();
}

export async function put(data) {
    data.IsRead = true;
    return fetchWrapper('/api/notifications', {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export async function deleteMessage(id) {
    return fetchWrapper('/api/notifications?id=' + id, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export async function getCount() {
    let response = await fetchWrapper('/api/notifications/count', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response?.json();
}

export async function markNotification(data) {
    let body = { ids: data, isRead: true };

    return fetchWrapper('/api/notifications/mark', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
}

export async function deleteNotification(data) {
    let body = { ids: data };

    return fetchWrapper('/api/notifications/delete', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
}
