import jwtDecode from 'jwt-decode';
import { sendRefreshTokenRequest } from '../auth';

let isRefreshingToken = false;
let callQueue = [];

class TokenService {
    getLocalRefreshToken() {
        return localStorage.getItem('refresh_token');
    }

    setLocalRefreshToken(refreshToken) {
        localStorage.setItem('refresh_token', refreshToken);
    }

    setLocalAccessToken(accessToken) {
        localStorage.setItem('access_token', accessToken);
    }

    isLocalAccessTokenExpired() {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            return true;
        }

        try {
            const claims = jwtDecode(accessToken);
            const currentTime = Date.now() / 1000;

            return claims?.exp ? claims.exp < currentTime + 20 : true; //return claims.exp < currentTime + 10;
        } catch (error) {
            console.error(error.message);
            this.clearCache();
            return true;
        }
    }

    getUser() {
        const accessToken = localStorage.getItem('access_token');

        if (!accessToken) {
            return null;
        }

        try {
            const claims = jwtDecode(accessToken);

            return {
                email: claims.email,
                firstName: claims.firstName,
                lastName: claims.lastName,
                userId: claims.userId,
            };
        } catch (error) {
            console.error(error.message);
            this.clearCache();
            return null;
        }
    }

    clearCache() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('user');
    }

    async refreshToken() {
        if (isRefreshingToken) {
            return new Promise((resolve, reject) => {
                callQueue.push({ resolve, reject });
            });
        }

        isRefreshingToken = true;
        const refreshToken = this.getLocalRefreshToken();

        if (!refreshToken) {
            this.clearCache();
            isRefreshingToken = false;
            return null;
        }

        try {
            const refreshAccessTokenResult = await sendRefreshTokenRequest(refreshToken);

            if (
                !refreshAccessTokenResult?.access_token ||
                !refreshAccessTokenResult?.refresh_token
            ) {
                this.clearCache();
                isRefreshingToken = false;
                return null;
            }

            this.setLocalRefreshToken(refreshAccessTokenResult.refresh_token);
            this.setLocalAccessToken(refreshAccessTokenResult.access_token);

            const user = this.getUser();
            if (user?.email) {
                localStorage.setItem('user', JSON.stringify(user));
            }

            isRefreshingToken = false;
            callQueue.forEach(({ resolve }) => resolve(refreshAccessTokenResult.access_token));
            callQueue = [];

            return refreshAccessTokenResult ? refreshAccessTokenResult.access_token : null; //return refreshAccessTokenResult.access_token;
        } catch (error) {
            this.clearCache();
            isRefreshingToken = false;
            callQueue.forEach(({ reject }) => reject(error));
            callQueue = [];
            return null;
        }
    }
}

const tokenService = new TokenService();
export default tokenService;
