import tokenService from '../api/services/token-service';
import { getBaseUrl } from './getBaseUrl';

export async function fetchWrapper(endpoint, options) {
    try {
        let token = localStorage.getItem('access_token');
        if (!token || tokenService.isLocalAccessTokenExpired()) {
            token = await tokenService.refreshToken();
            if (!token) {
                window.location.href = '/#/login';
                window.location.reload();
                throw new Error('Unable to obtain a new access token.');
            }
        }

        options.headers = {
            Authorization: `Bearer ${token}`,
            ...options.headers,
        };

        return fetch(getBaseUrl() + endpoint, options);
    } catch (error) {
        console.error(error);
        return new Response(JSON.stringify({}), {
            status: 200,
            headers: { 'Content-Type': 'application/json' },
        });
    }
}
