export const AUTH = {
    GRANT_TYPE_SIGN_IN: {
        key: 'grant_type',
        value: 'password',
    },
    GRANT_TYPE_REFRESH_TOKEN: {
        key: 'grant_type',
        value: 'refresh_token',
    },
    CLIENT_ID: {
        key: 'client_id',
        value: 'client-app',
    },
    CLIENT_SECRET: {
        key: 'client_secret',
        value: process.env.REACT_APP_API_KEY,
    },
};
