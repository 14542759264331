import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { signIn as sendSignInRequest } from '../api/auth';
import tokenService from '../api/services/token-service';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            if (storedUser) {
                setUser(storedUser);
            }

            setLoading(false);
        })();
    }, []);

    const signIn = useCallback(async (email, password) => {
        const signInResult = await sendSignInRequest(email, password);

        if (!signInResult?.access_token && !signInResult?.refresh_token) {
            return null;
        }

        tokenService.setLocalRefreshToken(signInResult.refresh_token);
        tokenService.setLocalAccessToken(signInResult.access_token);

        const storedUser = tokenService.getUser();

        if (storedUser.email !== undefined) {
            setUser(storedUser);
            localStorage.setItem('user', JSON.stringify(storedUser));
        }

        return storedUser;
    }, []);

    const signOut = useCallback(() => {
        tokenService.clearCache();
        setUser(undefined);
    }, []);

    return <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} />;
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
