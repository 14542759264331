import React from 'react';
import './ErrorFallback.css';

function ErrorFallback({ error, resetErrorBoundary }) {
    console.log(error);
    return (
        <div className='error-container'>
            <div className='error-content'>
                <img
                    rel='preload'
                    fetchpriority='high'
                    as='image'
                    width='170px'
                    className='error-logo'
                    height='80px'
                    src={process.env.PUBLIC_URL + '/assets/images/logo-fgcr2.svg'}
                    alt='logo'
                />
                <div className='error-header'>
                    <h2 className='error-heading'>Oops! Ceva nu a mers cum trebuie.</h2>
                    <p className='error-text'>
                        A apărut o eroare neașteptată. Te rugăm să încerci din nou.
                    </p>
                </div>
                <button className='error-button' onClick={resetErrorBoundary}>
                    Încercă din nou
                </button>
            </div>
        </div>
    );
}
export default ErrorFallback;
