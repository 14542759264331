export const getBaseUrl = () => {
    let url = '';
    switch (process.env.NODE_ENV) {
        case 'production':
            url = window.API_URL;
            break;
        case 'development':
            url = 'http://localhost:5045';
            break;
        default:
            return;
    }
    return url;
};
