import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';

import { getCount as getUnreadMessagesCounter } from '../api/services/user-notifications-services';

const NotificationContext = createContext({});
const useNotifications = () => useContext(NotificationContext);

function NotificationProvider(props) {
    const [counterData, setCounterData] = useState(0);
    useEffect(() => {
        async function setDataSourceAsync() {
            setCounterData(await getUnreadMessagesCounter());
        }
        setDataSourceAsync();
    }, [setCounterData]);

    const memoCounter = useMemo(
        () => ({
            counterData,
            setCounterData,
        }),
        [counterData]
    );

    return <NotificationContext.Provider value={memoCounter} {...props} />;
}

export { NotificationProvider, useNotifications };
