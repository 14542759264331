import notify from 'devextreme/ui/notify';
import { AUTH } from '../utils/auth-constants';
import { fetchWrapper } from '../utils/fetch-wrapper';
import { getBaseUrl } from '../utils/getBaseUrl';

export async function signIn(username, password) {
    try {
        let params = new URLSearchParams();
        params.append('username', username);
        params.append('password', password);
        params.append('type', 2);
        params.append(AUTH.GRANT_TYPE_SIGN_IN.key, AUTH.GRANT_TYPE_SIGN_IN.value);
        params.append(AUTH.CLIENT_ID.key, AUTH.CLIENT_ID.value);
        params.append(AUTH.CLIENT_SECRET.key, AUTH.CLIENT_SECRET.value);

        let response = await fetch(`${getBaseUrl()}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params,
        });

        if (response.status === 401) {
            return null;
        }

        return response.json();
    } catch {
        return {
            ok: false,
            message: 'Autentificare nereusita',
        };
    }
}

export async function sendRefreshTokenRequest(token) {
    try {
        let params = new URLSearchParams();
        params.append('refresh_token', token);
        params.append(AUTH.GRANT_TYPE_REFRESH_TOKEN.key, AUTH.GRANT_TYPE_REFRESH_TOKEN.value);
        params.append(AUTH.CLIENT_ID.key, AUTH.CLIENT_ID.value);
        params.append(AUTH.CLIENT_SECRET.key, AUTH.CLIENT_SECRET.value);

        let response = await fetch(`${getBaseUrl()}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params,
        });

        return response.json();
    } catch {
        return {
            ok: false,
            message: 'Va rugam incercati din nou',
        };
    }
}

export async function getUser() {
    try {
        // Send request

        return {
            isOk: true,
            data: undefined,
        };
    } catch {
        return {
            isOk: false,
        };
    }
}

export async function createAccount(data) {
    let response = await fetchWrapper('/api/users', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    return response.json();
}

export async function saveDataAfterTwoFactorAuthentication(data) {
    return fetchWrapper('/api/users/save-data-after-2fa', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export async function changePassword(userId, oldPassword, newPassword) {
    try {
        let credentials = {};
        credentials.UserId = userId;
        credentials.OldPassword = oldPassword;
        credentials.NewPassword = newPassword;

        let response = await fetchWrapper('/api/users/change-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });
        
        let responseObj = await response.json();
        if(String(responseObj?.detail ?? '').indexOf('Parola a mai fost folosită') >= 0) {
            notify(responseObj.detail, 'error', 5000);
            return;
        }

        if (!response.ok) {
            throw new Error();
        }

        return response;
    } catch {
        notify('Eroare la schimbarea parolei, vă rugăm reîncercați!', 'error', 5000);
    }
}

export async function resetPassword(dataEmail) {
    try {
        let response = await fetch(getBaseUrl() + '/api/account/forgot-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(dataEmail),
        });

        return response.text();
    } catch {
        return {
            ok: false,
            message: 'Eroare la resetarea parolei, vă rugăm reîncercați.',
        };
    }
}

export async function contactEmail(email, firstName, lastName, message) {
    try {
        let credentials = {};
        credentials.htmlBody = `
        <div>Nume: ${firstName} ${lastName}</div>
         <div>E-mail: ${email}</div> 
         <div>Mesajul:</div>
         <div>${message}</div>`;
        credentials.subject = `E-mail trimis de ${firstName} ${lastName}`;
        credentials.isForExternalApp = false;

        let response = await fetch(getBaseUrl() + '/api/emailsender', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        return response.text();
    } catch {
        return {
            ok: false,
            message: 'Eroare la trimitrearea mesajului, vă rugăm reîncercați.',
        };
    }
}

export async function confirmResetPassword(password, id, token) {
    try {
        let credentials = {};
        credentials.Password = password;
        credentials.UserId = id;
        credentials.Token = token;

        let response = await fetch(getBaseUrl() + '/api/account/confirm-reset-password', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(credentials),
        });

        let responseObj = await response.json();
        if(String(responseObj?.detail ?? '').indexOf('Parola a mai fost folosită') >= 0) {
            notify(responseObj.detail, 'error', 5000);
            return;
        }

        if (!response.ok) {
            throw new Error();
        }

        return response;
    } catch {
        notify('Eroare la schimbarea parolei, vă rugăm reîncercați!', 'error', 5000);
    }
}
