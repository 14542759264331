import React from 'react';
import './themes/custom-theme/dx.generic.custom-theme-fgcr.3.2024.css';
import './themes/custom-theme/custom-theme-changes.scss';

import LoadPanel from 'devextreme-react/load-panel';
import config from 'devextreme/core/config';
import { loadMessages, locale } from 'devextreme/localization';
import roMessages from 'devextreme/localization/messages/ro.json';
import { HashRouter as Router } from 'react-router-dom';

import { AuthProvider, useAuth } from './contexts/auth';
import { NotificationProvider } from './contexts/counter';
import { NavigationProvider } from './contexts/navigation';
import { createLoadableComponent } from './lodableHelper';
import { useScreenSizeClass } from './utils/media-query';

const UnauthenticatedContent = createLoadableComponent(() => import('./UnauthenticatedContent'));
const Content = createLoadableComponent(() => import('./Content'));
const licenseKey = process.env.REACT_APP_DEVEXTREME_KEY;

config({
    licenseKey,
});

function App() {
    const { user, loading } = useAuth();

    locale('ro');
    loadMessages(roMessages);

    if (loading) {
        return <LoadPanel visible={true} />;
    }

    if (user) {
        return (
            <NotificationProvider>
                <Content />
            </NotificationProvider>
        );
    }

    return <UnauthenticatedContent />;
}

export default React.memo(function Root() {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <AuthProvider>
                <NavigationProvider>
                    <div className={`app ${screenSizeClass}`}>
                        <App />
                    </div>
                </NavigationProvider>
            </AuthProvider>
        </Router>
    );
});
